import _click2 from "./click";
import _doubleClick2 from "./doubleClick";
import _hover2 from "./hover";
import _mouseDown2 from "./mouseDown";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _click = _interopRequireDefault(_click2);

var _doubleClick = _interopRequireDefault(_doubleClick2);

var _hover = _interopRequireDefault(_hover2);

var _mouseDown = _interopRequireDefault(_mouseDown2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = {
  click: _click["default"],
  doubleClick: _doubleClick["default"],
  hover: _hover["default"],
  mouseDown: _mouseDown["default"]
};
exports["default"] = _default;
export default exports;
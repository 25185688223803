import _doubleTap2 from "./doubleTap";
import _longTouch2 from "./longTouch";
import _tap2 from "./tap";
import _touch2 from "./touch";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _doubleTap = _interopRequireDefault(_doubleTap2);

var _longTouch = _interopRequireDefault(_longTouch2);

var _tap = _interopRequireDefault(_tap2);

var _touch = _interopRequireDefault(_touch2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = {
  doubleTap: _doubleTap["default"],
  longTouch: _longTouch["default"],
  tap: _tap["default"],
  touch: _touch["default"]
};
exports["default"] = _default;
export default exports;